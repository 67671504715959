import React, { useState, useEffect, useRef } from 'react'
import Link from './Link'
import { internalLinks } from '../assets/lib/constants'

const Navigation = (props) => {
  const navRef = useRef()

  const [isTop, setIsTop] = useState(false)
  const [offsetTop, setOffsetTop] = useState(0)

  const scrollTop = () => {
    if (typeof window === 'undefined') return 0
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  }

  useEffect(() => {
    // make top nav bar sticked on top
    // when scrolled more than offset position of nav bar
    const onScroll = () => {
      const position = scrollTop()

      if (position > offsetTop) {
        setIsTop(true)
        document.body.classList.add('with-fixed-nav')
      } else {
        setIsTop(false)
        document.body.classList.remove('with-fixed-nav')
      }
    }

    setOffsetTop(navRef.current.offsetTop)

    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
      
      setIsTop(false)
      document.body.classList.remove('with-fixed-nav')
    }
  }, [offsetTop])

  const navClass = isTop ? 'nav is_top-nav-fixed' : 'nav'
  
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : ''

  return (
    <nav className={navClass} ref={navRef}>
      <div className="nav__inner clearfix">
        <div className="nav__logo">
          <Link to="/"><img src="/image/common/logo_hz_white.svg" className="nav__logo-img" alt="もばらぶ" /></Link>
        </div>
          <ul className="nav__list">
            {internalLinks.map((link) => (
              <li className="nav__list-item" key={link.path}>
                <Link
                  to={link.path}
                  className={`nav__list-link ${currentPath === link.path && 'nav__list-link--active'}`}
                >
                  { link.title }
                </Link>
              </li>
            ))}
            <li className="nav__list-item">
              <Link href="https://blog.mobalab.net/inquiry/" className="nav__list-link">お問い合わせ</Link>
            </li>
            <li className="nav__list-item nav__list-item--blog">
              <div className="nav__list-link nav__list-link--blog">ブログ</div>
              <div className="nav__blog-popup">
                <Link className="nav__blog-link" href="https://blog.mobalab.net/">会社ブログ</Link>
                <Link className="nav__blog-link" href="https://engineering.mobalab.net/">技術者ブログ</Link>
              </div>
            </li>            
            <li className="nav__list-item">
              <Link href="https://twitter.com/mobalab" className="nav__list-link" target="_blank"><img className="header__twitter-logo" src="/image/common/twitter-logo.png" alt="twitter"></img></Link>
            </li>
          </ul>
          <div className="nav-toggle js-open-menu" onClick={props.onClickedMenuButton}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
